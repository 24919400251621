// import React from "react"
// import { Link } from "gatsby"

// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import Button from "../components/button"

// class IndexPage extends React.Component {
//   render() {
//     const siteTitle = "Gatsby Starter Personal Website"

//     return (
//       <Layout location={this.props.location} title={siteTitle}>
//         <SEO
//           title="Home"
//           keywords={[`blog`, `gatsby`, `javascript`, `react`]}
//         />
//         <img style={{ margin: 0 }} src="./GatsbyScene.svg" alt="Gatsby Scene" />
//         <h1>
//           Hey people{" "}
//           <span role="img" aria-label="wave emoji">
//             👋
//           </span>
//         </h1>
//         <p>Welcome to your new Gatsby website. You are on your home page.</p>
//         <p>
//           This starter comes out of the box with styled components and Gatsby's
//           default starter blog running on Netlify CMS.
//         </p>
//         <p>Now go build something great!</p>
//         <Link to="/blog/">
//           <Button marginTop="35px">Go to Blog</Button>
//         </Link>
//       </Layout>
//     )
//   }
// }

// export default IndexPage



import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import SearchPosts from "../components/searchPosts"

class IndexPage extends React.Component {
  render() {
    const { data, navigate, location } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    const localSearchBlog = data.localSearchBlog

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <Bio />
        <SearchPosts
          posts={posts}
          localSearchBlog={localSearchBlog}
          navigate={navigate}
          location={location}
        />
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
